import { Classes, GameTypes } from "../utils/utils"
import { GameStats } from "./GameStatsComponent"

export const ClassStatsPage: React.FC<any> = (props) => {
    const rankedGames = props.games.filter((game: any) => game.gameType === GameTypes.RANKED)
    const challengeGames = props.games.filter((game: any) => game.gameType === GameTypes.CHALLENGE)
    return (
        <div>
            <p style={{fontSize: 24, fontWeight: 'bold'}}>{props.class}</p>
            {rankedGames.length ? <div>
                <br/>
                RANKED GAMES
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <GameStats games={rankedGames}/>
            {Object.keys(Classes).map((className: any) => {
                // @ts-ignore
                const classGames = rankedGames.filter((game: any) => game.enemyClass === Classes[className])
                if(classGames.length) {
                    return <div>
                        AGAINST {className}:
                {/* @ts-ignore */}
                        <GameStats games={rankedGames.filter((game: any) => game.enemyClass === Classes[className])}/>
                        </div>
                }
                return undefined
            })}
            </div>
                </div> : undefined}
            {challengeGames.length ? <div>
            <br/>
                CHALLENGE GAMES
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <GameStats games={challengeGames}/>
            {Object.keys(Classes).map((className: any) => {
                // @ts-ignore
                const classGames = challengeGames.filter((game: any) => game.enemyClass === Classes[className])
                if(classGames.length) {
                    return <div>
                        AGAINST {className}:
                {/* @ts-ignore */}
                        <GameStats games={challengeGames.filter((game: any) => game.enemyClass === Classes[className])}/>
                        </div>
                }
                return undefined
            })}
            </div>
                </div> : undefined}
            
            
            
        </div>
    )
} 