import { Results } from "../utils/utils"

export const GameStats: React.FC<any> = (props) => {
    
    return (
        <div style={{marginLeft: '25px', marginRight: '25px'}}>
            ALL: {props.games.length}
            <br/>
            WIN: {props.games.filter((game: any) => game.result === Results.WIN).length}
            <br/>
            LOSE: {props.games.filter((game: any) => game.result === Results.LOSE).length}
            <br/>
            WINRATIO: {(props.games.filter((game: any) => game.result === Results.WIN).length/(props.games.filter((game: any) => game.result === Results.WIN).length+props.games.filter((game: any) => game.result === Results.LOSE).length))*100}%
        
            <br/>
            <br/>
        </div>
    )
} 