import { useState } from "react"
import { Classes, GameTypes, groupBy } from "../utils/utils";
import { ClassStatsPage } from "./ClassStatsPage";
import { GameStats } from "./GameStatsComponent";

export const StatsMainPage: React.FC<{}> = () => {
    const [games] = useState(JSON.parse(localStorage.getItem("games") || "[]"))
    const groupByClass = groupBy('userClass');
    const groupedGames = groupByClass(games)
    const rankedGames = games.filter((game: any) => game.gameType === GameTypes.RANKED)
    const challengeGames = games.filter((game: any) => game.gameType === GameTypes.CHALLENGE)
    
    return (
        <div>
            <div>
            <p style={{fontSize: 24, fontWeight: 'bold'}}>ALL CLASSES</p>
            {rankedGames.length ? <div>
                <br/>
                RANKED GAMES
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <GameStats games={games}/>
            {Object.keys(Classes).map((className: any) => {
                // @ts-ignore
                const classGames = rankedGames.filter((game: any) => game.enemyClass === Classes[className])
                if(classGames.length) {
                    return <div>
                        AGAINST {className}:
                {/* @ts-ignore */}
                        <GameStats games={rankedGames.filter((game: any) => game.enemyClass === Classes[className])}/>
                        </div>
                }
                return undefined
            })}
            </div>
                </div> : undefined}
            {challengeGames.length ? <div>
            <br/>
                CHALLENGE GAMES
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <GameStats games={challengeGames}/>
            {Object.keys(Classes).map((className: any) => {
                // @ts-ignore
                const classGames = challengeGames.filter((game: any) => game.enemyClass === Classes[className])
                if(classGames.length) {
                    return <div>
                        AGAINST {className}:
                {/* @ts-ignore */}
                        <GameStats games={challengeGames.filter((game: any) => game.enemyClass === Classes[className])}/>
                        </div>
                }
                return undefined
            })}
            </div>
                </div> : undefined}
            
            
            
        </div>
            {Object.keys(groupedGames).map((className: any) => {
            return <ClassStatsPage class={className} games={groupedGames[className]}/>
        })}</div>
    )
} 