import { Classes, GameTypes, Results } from "../utils/utils"
import React, { useState } from 'react';

export const AddGamePage: React.FC<any> = () => {
    let [userClass, setUserClass] = useState()
    let [enemyClass, setEnemyClass] = useState()
    let [result, setResult] = useState()
    let [gameType, setGameType] = useState()

    const saveGame = () => {
        const games = JSON.parse(localStorage.getItem("games") || "[]");
        const newGame = {
            userClass, enemyClass, result, gameType
        }
        games.push(newGame)
        localStorage.setItem('games', JSON.stringify(games))
        window.location.reload();
    }
    
    return (
        <div>
            <label>Your Class:</label>
             {/* @ts-ignore */}
            <select onChange={(e) => setUserClass(e.target.value)}>
                <option value=''></option>
                {Object.keys(Classes).map((c: any) => {
                    // @ts-ignore
                    return <option key={c} value={Classes[c]}>{Classes[c]}</option>
                })}
            </select>
            <br/>
            <label>Enemy Class:</label>
             {/* @ts-ignore */}
            <select onChange={(e) => setEnemyClass(e.target.value)}>
                <option value=''></option>
                {Object.keys(Classes).map((c: any) => {
                    // @ts-ignore
                    return <option key={c} value={Classes[c]}>{Classes[c]}</option>
                })}
            </select>
            <br/>
            <label>Result:</label>
             {/* @ts-ignore */}
            <select onChange={(e) => setResult(e.target.value)}>
                <option value=''></option>
                {Object.keys(Results).map((c: any) => {
                    // @ts-ignore
                    return <option key={c} value={Classes[c]}>{Results[c]}</option>
                })}
            </select>
            <br/>
            <label>Game type:</label>
             {/* @ts-ignore */}
            <select onChange={(e) => setGameType(e.target.value)}>
                <option value=''></option>
                {Object.keys(GameTypes).map((c: any) => {
                    // @ts-ignore
                    return <option key={c} value={Classes[c]}>{GameTypes[c]}</option>
                })}
            </select>
            <br/>
            <button onClick={() => saveGame()}>Save</button>
        </div>
        
    )
} 