import './App.css';
import { AddGamePage } from './addGame/AddGamePage';
import { StatsMainPage } from './stats/StatsMainPage';

function App() {
  return (
    <div>
      <AddGamePage/>
      <StatsMainPage/>
    </div>
  );
}

export default App;
