export const Classes = {
    FIGHTER: 'Fighter',
    CLERIC: 'Cleric',
    WIZARD: 'Wizard',
    THIEF: 'Thief',
    RANGER: 'Ranger'
}

export const Results = {
    WIN: 'WIN',
    LOSE: 'LOSE'
}


export const GameTypes = {
    RANKED: 'Ranked',
    CHALLENGE: 'Challenge'
}

export const groupBy = (key: string | number) => (array: any[]) => {
    return array.reduce((objectsByKeyValue: { [x: string]: any; }, obj: { [x: string]: any; }) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
}